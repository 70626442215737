<template>
  <div class="app-container">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px">
        <div class="extra">
          <input type="text" name="hidden1" />
          <input type="password" name="hidden2" />
          <div></div>
        </div>
        <el-form-item label="员工姓名：" prop="name">
          <el-input v-model="form.name" maxlength="15" placeholder="请输入员工姓名，长度不能大于15个字符"></el-input>
        </el-form-item>
        <el-form-item label="员工编号：">
          <el-input v-model="form.staffCode" placeholder="请输入至少五个字符"></el-input>
        </el-form-item>
        <el-form-item label="绑定方式：" prop="regType">
          <el-select v-model="form.regType" placeholder="请选择" @change="changeReg">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机：" :class="{ required: form.regType === 1, 'is-error': errorPhone }">
          <el-input v-model="form.mobile" placeholder="请输入手机号" @blur="validateTel(form.mobile)"> </el-input>
          <div v-show="errorPhone" class="el-form-item__error">{{ phoneText }}</div>
        </el-form-item>
        <el-form-item label="邮箱：" :class="{ required: form.regType === 2, 'is-error': errorEmail }">
          <el-input v-model="form.email" placeholder="请输入邮箱地址" @blur="validateEmail(form.email)"> </el-input>
          <div v-show="errorEmail" class="el-form-item__error">{{ emailText }}</div>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <div class="psdbox">
            <el-input v-model="form.password" placeholder="请输入最少8位字符"></el-input>
          </div>
        </el-form-item>
        <!-- <el-form-item label="用户组：" prop="ugid">
        <el-select v-model="form.ugid" clearable placeholder="请选择">
          <el-option v-for="item in grpoptions" :key="item.ugid" :label="item.ugname" :value="item.ugid">
          </el-option>
        </el-select>
      </el-form-item> -->
        <el-form-item label="角色：" prop="roles">
          <div class="role-box" @click="openDialog">
            <div class="role-detail" id="roleContent" v-text="allRoleText()" placeholder="请选择"></div>
            <span
              class="el-select__caret el-input__icon el-icon-arrow-down"
              :class="{ 'is-reverse': roleDialog }"
            ></span>
          </div>
        </el-form-item>
        <el-form-item label="组织权限：" prop="orgAuth">
          <div class="session1" ref="shopBox">
            <div class="shop-content" @click="showTree">
              <ul class="shop-name" :class="{ 'disabled-input': form.ugid }">
                <li v-for="(item, index) in checkNodes" :key="index">
                  {{ item.name }}<i class="icon-delete" @click.stop="deleteNode(item)">×</i>
                </li>
              </ul>
              <span
                class="el-select__caret el-input__icon el-icon-arrow-down"
                :class="{ 'is-reverse': treeIsShow }"
              ></span>
              <!-- <div v-show="errorOrg" class="el-form-item__error">请选择</div> -->
            </div>
            <div class="treeBox mb20" v-show="treeIsShow" style="display: none">
              <div class="tree-content">
                <el-input placeholder="请输入客户组织名称" v-model="filterText"> </el-input>
                <el-tree
                  node-key="orgcode"
                  :data="shopsTree"
                  :props="defaultProps"
                  :default-expanded-keys="orgcode"
                  ref="tree"
                  :filter-node-method="filterNode"
                  :expand-on-click-node="false"
                  :highlight-current="true"
                  show-checkbox
                >
                </el-tree>
              </div>
              <div class="dialog-btn borderTop">
                <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
                <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">新建</el-button>
          <el-button @click="() => this.$router.go(-1)">取消</el-button>
        </el-form-item>
      </el-form>

      <el-dialog title="角色管理" :visible.sync="roleDialog" width="531px" :before-close="handleClose">
        <div class="">
          <div class="role-left">
            <div v-for="(item, index) in roleList" v-show="!item.addRight">
              <div class="checktitle">新零售-{{ item.pname }}</div>
              <el-radio
                class="rediocontain"
                v-model="item.radioValue"
                v-for="(ele, key) in item.roleList"
                :label="ele.rid"
                >{{ ele.rname }}
              </el-radio>
            </div>
          </div>
          <div class="transfer">
            <el-button @click="addRole" :disabled="addBtn">添加 ></el-button>
            <el-button @click="cancelSelect" :disabled="!selectList.length"> < 取消</el-button>
          </div>
          <div class="role-right">
            <div v-for="(item, index) in roleList" v-show="item.addRight">
              <div class="checktitle">新零售-{{ item.pname }}</div>
              <el-radio class="rediocontain" v-model="selectList[index]" :label="item.pid"
                ><span v-text="selectName(item)"></span>
              </el-radio>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="roleDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitRole">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { orgShopTree, getEnableUserGroupList, addUser, getPlatRoles } from 'src/api/legacy'

export default {
  data() {
    var valiconcatTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      setTimeout(() => {
        if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
          callback(new Error('请输入正确的电话号码或邮箱'))
        } else {
          //校验手机号重复性
          callback()
        }
      })
    }
    var valiconcatEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      setTimeout(() => {
        if (!/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/.test(value)) {
          callback(new Error('请输入正确的电话号码或邮箱'))
        } else {
          //校验手机号重复性
          callback()
        }
      })
    }
    // const onload = () => {
    //   roleContent.onmouseover = function () {
    //     this.title = this.innerText
    //   }
    // }
    var validateOrgAuth = (rule, value, callback) => {
      if (!this.orgcode.length) {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    return {
      treeIsShow: false, //树显示
      checkNodes: [], //默认组织树名
      showNodes: [], //默认组织树名
      getTreeOrgcode: [], //选中树组织数据
      shopsTree: [],
      defaultProps: {
        label: 'name',
        children: 'sublist',
      },
      form: {
        name: '',
        staffCode: '',
        regType: 1,
        mobile: '',
        email: '',
        password: '',
        ugid: '',
        roles: [],
      },
      options: [
        {
          value: 1,
          label: '手机注册',
        },
        {
          value: 2,
          label: '邮箱注册',
        },
      ],
      grpoptions: [],
      filterText: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入员工姓名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 15,
            message: '长度在 8 到 15 个字符',
            trigger: 'blur',
          },
        ],
        regType: [
          {
            required: true,
            message: '请选择绑定方式',
            trigger: 'change',
          },
        ],
        roles: [
          {
            required: true,
            message: '请选择',
            trigger: 'click',
          },
        ],
        orgAuth: [
          {
            required: true,
            validator: validateOrgAuth,
            trigger: 'change',
          },
        ],
      },
      errorPhone: false,
      phoneText: '',
      errorEmail: false,
      emailText: '',
      errorOrg: false,

      roleList: [], //源数据
      PlatroleList: [],
      selectList: [],
      roleDialog: false,
    }
  },
  methods: {
    validateTel(val) {
      if (this.form.regType !== 1 && val === '') {
        this.errorPhone = true
        this.phoneText = '不能为空'
        return false
      } else if (val !== '' && !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(val)) {
        this.errorPhone = true
        this.phoneText = '请输入正确的手机号'
        return false
      } else {
        //校验手机号重复性
        this.errorPhone = false
        return true
      }
    },
    validateEmail(val) {
      if (this.form.regType === 2 && val === '') {
        this.errorEmail = true
        this.emailText = '不能为空'
        return false
      } else if (val !== '' && !/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,5}(\.[a-z]{2})?)$/.test(val)) {
        this.errorEmail = true
        this.emailText = '请输入正确的邮箱格式'
        return false
      } else {
        //校验手机号重复性
        this.errorEmail = false
        return true
      }
    },
    validateOrg() {
      if (!this.orgcode.length) {
        this.errorOrg = true
        return false
      } else {
        this.errorOrg = false
        return true
      }
    },
    changeReg(val) {
      if (val === 1) {
        this.errorEmail = false
      } else {
        this.errorPhone = false
      }
    },
    OrgShopTree() {
      //获取树文件数据
      return orgShopTree().then((res) => {
        if (res.data && res.status === 0) {
          res.data.disabled = false
          this.shopsTree = [res.data]
        }
      })
    },
    hideAll() {
      //隐藏树组织
      this.treeIsShow = false
    },
    treeClick() {
      //显示树组织
      this.filterText = ''
      this.$refs.tree.setCheckedNodes(this.checkNodes)
      this.treeIsShow = true
    },
    showTree() {
      if (this.form.ugid) return
      this.treeClick()
    },
    filterNode(value, data, node) {
      // 如果什么都没填就直接返回
      if (!value) return true
      // 如果传入的value和data中的label相同说明是匹配到了
      const reg = new RegExp(value, 'i')
      if (reg.test(data.name)) {
        return true
      }
      // 否则要去判断它是不是选中节点的子节点
      return this.checkBelongToChooseNode(value, data, node)
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBelongToChooseNode(value, data, node) {
      const level = node.level
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false
      }
      // 先取当前节点的父节点
      let parentData = node.parent
      // 遍历当前节点的父节点
      let index = 0
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.name.indexOf(value) !== -1) {
          return true
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent
        index++
      }
      // 没匹配到返回false
      return false
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(333, this.filterNode())
    },
    getOrgcode() {
      const nodes = this.$refs.tree.getCheckedNodes()
      this.checkNodes = this.filter(nodes)
      this.treeIsShow = false
      this.validateOrg()
    },
    filter(checkNodes) {
      const orgcodeArr = checkNodes.map((n) => n.orgcode)
      const index = orgcodeArr.findIndex((item) => item === '0')
      orgcodeArr.splice(index, 1)
      return checkNodes.filter((v) => !orgcodeArr.includes(v.parentCode))
    },
    deleteNode(node) {
      const index = this.checkNodes.findIndex((v) => {
        return node === v
      })
      this.checkNodes.splice(index, 1)
      this.$refs.tree.setCheckedNodes(this.checkNodes)
      this.validateOrg()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (this.form.regType === 1) {
          if (!this.validateTel(this.form.mobile) || !this.validateOrg()) return
          if (this.form.email !== '' && !this.validateEmail(this.form.email)) return
        } else {
          if (!this.validateEmail(this.form.email) || !this.validateOrg()) return
          if (this.form.mobile !== '' && !this.validateTel(this.form.mobile)) return
        }
        if (valid) {
          this.addUser()
        }
      })
    },
    getEnableUserGroupList() {
      //查询用户组
      getEnableUserGroupList().then((res) => {
        if (res && res.status === 0) {
          this.grpoptions = res.data
        }
      })
    },
    addUser() {
      //新增
      let orgs = this.orgcode.filter((item) => item !== '0')
      const roles = this.form.roles.map((v) => v.radioValue)
      this.form.ugid = this.form.ugid === '' ? 0 : this.form.ugid
      let data = Object.assign({}, this.form, {
        orgs,
        roles,
      })
      addUser(data).then((res) => {
        if (res.data) {
          this.$message({
            message: '保存成功',
            type: 'success',
          })
          this.$router.push({
            name: 'userTable',
            value: this.form.regType === 1 ? this.form.mobile : this.form.email,
          })
        } else {
          this.$message({
            message: '保存失败',
            type: 'success',
          })
        }
      })
    },

    getRoleList() {
      //查询各平台角色
      getPlatRoles().then((res) => {
        if (res && res.status === 0) {
          if (res.status == 0) {
            this.roleList = res.data.map((v) => {
              return {
                ...v,
                radioValue: '',
                addRight: false,
              }
            })
          }
        }
      })
    },
    addRole() {
      //添加
      this.roleList.forEach((item) => {
        if (item.radioValue) {
          item.addRight = true
        }
      })
    },
    cancelSelect() {
      //取消
      this.roleList.forEach((item) => {
        if (this.selectList.includes(item.pid)) {
          item.addRight = false
          item.radioValue = ''
        }
      })
      this.selectList = []
    },
    selectName(item) {
      if (item.radioValue) {
        return item.roleList.find((v) => {
          return v.rid === item.radioValue
        }).rname
      }
    },
    submitRole() {
      this.roleDialog = false
      const list = JSON.parse(JSON.stringify(this.roleList))
      this.form.roles = list.filter((v) => {
        return v.radioValue && v.addRight === true
      })
      //校验
      if (this.form.roles.length) {
        $('.role-box+.el-form-item__error').hide()
      } else {
        $('.role-box+.el-form-item__error').show()
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    openDialog(index) {
      this.roleDialog = true
      this.form.roles.forEach((v) => {
        const i = this.roleList.findIndex((r) => r.pid === v.pid)
        this.roleList[i].radioValue = v.radioValue
        this.roleList[i].addRight = true
      })
    },
    allRoleText() {
      return (
        this.form.roles && this.form.roles.map((v) => (v.pname ? `${v.pname}： ${this.selectName(v)}` : '')).join('、')
      )
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    // 'form.ugid'(val) {
    //   if (val !== '') {
    //     this.errorRoles = false
    //     this.errorOrg = false
    //     this.form.roles = ''
    //     this.checkNodes = []
    //     this.$refs.tree.setCheckedNodes(this.checkNodes)
    //   }
    // }
  },
  computed: {
    orgcode() {
      return this.checkNodes.length ? this.checkNodes.map((v) => v.orgcode) : []
    },
    addBtn() {
      return this.roleList.every((item) => {
        return item.radioValue === '' || item.addRight
      })
    },
  },
  created() {},
  mounted() {
    this.OrgShopTree()
    // this.getPlatRoles()
    this.getEnableUserGroupList()
    this.getRoleList()
    document.addEventListener('click', (e) => {
      if (!this.$refs.shopBox.contains(e.target)) {
        this.treeIsShow = false
      }
    })
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.el-select .el-input .el-input__inner,
.el-input__inner {
  height: 36px;
  line-height: 36px;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.navtitle {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
  font-size: 14px;
}

.content {
  padding-top: 40px;
  font-size: 12px;
  padding-left: 100px;
}

.el-input {
  width: 468px;
}

.detail.el-form-item.is-required .el-form-item__label:before {
  content: '';
}

.psdbox {
  width: 468px;
  position: relative;
}

.extra {
  position: relative;
}

.extra div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}

/* 组织权限 */
.session1 {
  /* padding:20px 20px 0 20px;
  margin: 0 -20px;
  align-items: center; */
}

.el-tree {
  max-height: 268px;
  overflow-y: auto;
}

.session1 .shop-content {
  position: relative;
  width: 468px;
}

.shop-content .shop-name {
  width: 468px;
  min-height: 36px;
  line-height: 36px;
  padding: 0px 24px 0px 10px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.shop-content .shop-name:hover,
.shop-content .shop-name:focus {
  border-color: #c0c4cc;
}

.session1 .disabled-input {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}

.shop-content .disabled-input:hover,
.shop-content .disabled-input:focus {
  border-color: #dcdfe6;
}

.el-form-item.is-error .shop-name {
  border-color: #f56c6c;
}

.shop-name li {
  height: 22px;
  line-height: 22px;
  padding: 0 20px 0 8px;
  display: inline-block;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  position: relative;
}

li .icon-delete {
  position: absolute;
  right: 0;
  padding: 0 8px 0 10px;
}

.treeBox {
  width: 468px;
  left: 0;
  top: auto;
  margin-top: 10px;
}

.shop-content .el-icon-arrow-down,
.role-box .el-icon-arrow-down {
  color: #c0c4cc;
  position: absolute;
  top: 0px;
  right: 6px;
}

.is-reverse {
  transform: rotate(180deg);
}

.session1 .treeBox {
  left: 0;
}

.tree-content .el-input {
  padding: 16px 24px;
}

.dialog-btn {
  padding: 10px;
}

.required .el-form-item__label:before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

/* 角色管理弹框 */
.role-box {
  width: 468px;
  position: relative;
}
.role-detail {
  width: 468px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #dcdfe6;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.role-left,
.role-right {
  width: 200px;
  height: 214px;
  overflow: auto;
  border: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 12px 20px;
}

.role-left .el-radio,
.role-left .checktitle,
.role-right .el-radio,
.role-right .checktitle {
  margin-top: 12px;
  display: block;
}

.role-left .el-radio,
.role-right .el-radio {
  margin-left: 10px;
}

.role-left .el-radio + .el-radio,
.role-right .el-radio + .el-radio {
  margin-left: 10px;
}

.comColor {
  color: #5c76cc;
  cursor: pointer;
}

.transfer .el-button.el-button--default.is-disabled,
.el-transfer__buttons .el-transfer__button.is-disabled,
.el-transfer__buttons .el-transfer__button.is-disabled:hover {
  border: 1px solid #dcdfe6;
  background-color: #f5f7fa;
  color: #c0c4cc;
}

.transfer .el-button.el-button--default {
  background: #5c76cc;
  border: 1px solid #5c76cc;
  color: #fff;
}

.transfer {
  width: 80px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.transfer .el-button + .el-button {
  margin-left: 0px;
  margin-top: 10px;
}
</style>